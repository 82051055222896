import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        name: 'index',
        path: '',
        component: () => import('pages/IndexPage.vue'),
      },
      {
        name: 'iframe',
        path: 'iframe',
        component: () => import('pages/IframePage.vue'),
      },
      {
        name: 'order-graph',
        path: 'order-graph',
        component: () => import('pages/OrderGraphPage.vue'),
      },
    ],
  },
  {
    name: 'callback',
    path: '/callback',
    component: () => import('pages/CallbackPage.vue'),
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue'),
  },
];

export default routes;
