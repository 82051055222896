import { defineStore } from 'pinia';
import { ref } from 'vue';
import { LocationQueryValue, LocationQuery } from 'vue-router';
import { graphTemplates } from 'src/application/graph-templates';
import { PageSettings } from 'src/application/models/PageSettings';

export const useSettingsStore = defineStore('settings-store', () => {
  const initialized = ref(false);
  const pageSettings = ref<PageSettings>(new PageSettings());

  const formatToType = (
    value: LocationQueryValue | LocationQueryValue[],
    type: string
  ) => {
    if (type === 'string') {
      return value as string;
    } else if (type === 'number') {
      return Number(value);
    } else if (type === 'boolean') {
      return value === 'true';
    } else if (type === 'array') {
      return Array.isArray(value) ? value : [value];
    } else {
      return value;
    }
  };

  const parseUrlQuery = (query: LocationQuery) => {
    const templateId = query.template;

    pageSettings.value.iframeView = query.iframe === 'true';

    if (templateId) {
      const graphTemplate = graphTemplates[templateId as string];

      pageSettings.value.graphTemplateLabel = graphTemplate.label;
      pageSettings.value.graphTemplateQuery = graphTemplate.query;

      pageSettings.value.graphTemplateParameters = {};
      const templateParameters = graphTemplate.queryParameters;
      if (templateParameters) {
        pageSettings.value['graphTemplateParameters'] = Object.fromEntries(
          Object.entries(templateParameters).map(([paramName, paramType]) => {
            if (!query[`templateParams.${paramName}`]) {
              throw new Error(`Parameter ${paramName} is missing`);
            }
            return [
              paramName,
              formatToType(query[`templateParams.${paramName}`], paramType),
            ];
          })
        );
      }

      pageSettings.value.graphTemplateCreateGhostPath =
        graphTemplate.createGhostPath || false;
    }
  };

  const init = (query: LocationQuery) => {
    if (!initialized.value) {
      parseUrlQuery(query);
      initialized.value = true;
    }
  };

  return {
    pageSettings,
    init,
  };
});
