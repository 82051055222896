import AuthService from '@gis/easy-oidc';
import UrlUtils from 'src/application/utils/UrlUtils';
import { boot } from 'quasar/wrappers';
import { Configuration } from 'src/application/utils/ConfigUtils';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $auth: AuthService;
  }
}

function auth() {
  const identity = Configuration.identity;

  const authority =
    identity?.authority ?? (import.meta.env.VITE_IDENTITY_AUTHORITY as string);
  const clientId =
    identity?.client_id ?? (import.meta.env.VITE_IDENTITY_CLIENT_ID as string);
  const scope =
    identity?.scope ?? (import.meta.env.VITE_IDENTITY_SCOPE as string);
  const midPath =
    Configuration.midPath ?? (import.meta.env.VITE_IDENTITY_MID_PATH as string);
  const __auth = AuthService.withConfig(
    {
      authority: UrlUtils.processUrl(authority) ?? '',
      client_id: clientId,
      scope: scope,
    },
    midPath
  );
  const auth = AuthService.withConfig({
    authority: UrlUtils.processUrl(authority) ?? '',
    client_id: clientId,
    scope: scope,
    redirect_uri: `${__auth.originPath}/#/callback`,
    silent_redirect_uri: `${__auth.originPath}/oidc/silent-renew.html`,
    post_logout_redirect_uri: `${__auth.originPath}`,
  });
  return auth;
}

export default boot(async ({ app }) => {
  app.config.globalProperties.$auth = auth();
});

export { auth };
