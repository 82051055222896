export default class UrlUtils {
  static processUrl(url: string | null | undefined): string | undefined {
    if (!url) return;
    if (!url.includes('{tenant}')) return url;
    const currentUrl = new URL(window.location.href);
    const hostname = currentUrl.hostname.replace('www.', '');
    const tenant = hostname.split('.')[0];
    return url.replace('{tenant}', tenant);
  }
}
