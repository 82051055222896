type GraphTemplate = {
  label: string;
  query: string;
  queryParameters?: Record<string, string>;
  createGhostPath?: boolean;
};

export const graphTemplates = <Record<string, GraphTemplate>>{
  '001': {
    label: 'In welche Räume kommt diese Person? (Gruppiert nach Gebäuden)',
    query:
      'MATCH (p:Person {uid: $personUid})-->(:AccessGroup)-->(:Terminal)<--(:Door)<--(r:Room)<--(:Floor)<--(b:Building) RETURN p, b, r',
    queryParameters: { personUid: 'string' },
    createGhostPath: true,
  },
  '002': {
    label: 'Welche Person hat Zutritt zu diesem Raum?',
    query:
      'MATCH (r:Room {name: $roomName})-->(:Door)-->(:Terminal)<--(:AccessGroup)<--(p:Person) RETURN r, p',
    queryParameters: { roomName: 'string' },
    createGhostPath: true,
  },
};
