import { loadConfig } from '@gis/easy-config';
import { tryGetConfigFromSessionStorage } from '@gis/easy-config/dist/ConfigLoader';
import { Identity } from 'src/application/types/config/Identity';

class Configuration {
  static records: Record<string, unknown>;

  static get identity(): Identity | null {
    return Configuration.getSection<Identity>('identity');
  }

  static get midPath(): string | null {
    return Configuration.getSection<string>('midPath');
  }

  static get graphqlPath(): string | null {
    return Configuration.getSection<string>('graphql');
  }

  static get graphqlSubscriptionPath(): string | null {
    return Configuration.getSection<string>('graphqlSubscription');
  }

  static get themeColors(): Record<string, string> | null {
    return Configuration.getSection<Record<string, string>>('themeColors');
  }
  static get appId(): string | null {
    return Configuration.getSection<string>('appId');
  }

  static get icon(): string | null {
    return Configuration.getSection<string>('icon');
  }

  static get configName(): string {
    return import.meta.env.VITE_APP_CONFIG_NAME ?? 'config';
  }

  static getSection<T>(section: string): T | null {
    if (import.meta.env.DEV) return null;
    if (!Configuration.records) {
      const records = tryGetConfigFromSessionStorage(this.configName);
      if (!records) throw 'The config is not loaded properly';
      Configuration.records = records;
    }
    const value = Configuration.records[section];
    if (!value) return null;
    return value as T;
  }

  static async init(configName?: string): Promise<void> {
    const name = configName ?? this.configName;
    Configuration.records = await loadConfig(name);
  }
}

export { Configuration };
